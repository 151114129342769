<template>
  <div>
    <!-- Top page !-->
    <v-row style="height: 165px">
      <v-col cols="2">
        <LisonsStaticComponent class="ml-5">
        </LisonsStaticComponent>
      </v-col>


      <!-- Activities Row !-->
      <v-col v-if="learner" class="ml-6">
        <v-row no-gutters>
          <v-col cols="1">
            <InterGroupItem
                class="mt-7"
                :inter-group="parseInt(learner.cycleId[1])"
                :parcours-letter="learner.cycleId[0]"
                :learner-current-inter-group="parseInt(learner.cycleId[1])">
            </InterGroupItem>
          </v-col>
          <v-col>
            <ActivitiesRow
                class="mt-8"
                :show-eval="showEvalRow"
                :show-practice="showPracticeRow"
                :is-intermediate="true"
                :learner-intra-group="learnerIntraGroup"
                :learner-practice-list-nb="learner.practiceListNumber">
            </ActivitiesRow>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Middle page !-->
    <LetterRow
        :class="{'hidden' : !showRow}"
        class="mt-16"
        :is-first-part-over="false"
        :display-all="showAllLetters"
        :items-row="resultsNotPassed"
        :is-intermediate="true">
    </LetterRow>

    <!-- Bottom Page !-->
    <v-row v-if="showTablet">
      <v-col cols="5" class="ml-8">
        <h1
            style="font-size: 5rem;"
            class="black--text pb-4"
        >
          <AnalysedSentence
              v-if="showExample"
              :sentence="sentenceExample"
              :show-analysis="showExampleAnalysis"
          >
          </AnalysedSentence>
        </h1>

      </v-col>
      <v-col class="d-flex justify-center align-end mb-14">
        <SpeakerComponent
            @speaker-clicked-on="playTextToSpeech(currentTarget, false)"
        >
        </SpeakerComponent>
      </v-col>
      <v-col>
        <SoundTablet
            class="mt-8 mr-16"
            learning-group="D4"
            :sound-tablets-items="soundTabletsDebutantItems"
            :is-tablet-opaque="true"
            :letter-to-highlight="letterToHighlight"
        >
        </SoundTablet>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {playTTS} from "../../services/ttsService";
import LetterRow from "../TextAnalysis/LetterRow";
import {getAllLearningGroups, getIntraGroup} from "../../utils/cycleUtils";
import cycleService from "../../services/cycleService";
import {getLearner} from "../../services/learnerService";
import LisonsStaticComponent from "../Items/Lisons/LisonsStaticComponent";
import InterGroupItem from "../Items/SoundHouse/InterGroupItem";
import ActivitiesRow from "../Items/ActivitiesRow/ActivitiesRow";
import SpeakerComponent from "../Items/TextToSpeech/SpeakerComponent";
import SoundTablet from "../Items/Tablet/SoundTablet";
import resultsService from "../../services/resultsService";
import {playGraphemeAudioFile} from "../../utils/globalUtils";
import AnalysedSentence from "../TextAnalysis/AnalysedSentence";
import PhoneticAnalysisService from "../../services/phoneticAnalysisService";

export default {
  name: "IntermediatePresentation",
  components: {
    AnalysedSentence,
    SoundTablet, SpeakerComponent, ActivitiesRow, InterGroupItem, LisonsStaticComponent, LetterRow
  },
  data() {
    return {
      learner: undefined,
      showAllLetters: true,
      resultsNotPassed: [],
      learnerIntraGroup: undefined,
      soundTabletsDebutantItems: [],
      letterToHighlight: '',
      showRow: false,
      showTablet: false,
      showEvalRow: false,
      showPracticeRow: false,
      currentTarget: undefined,
      indexTarget: -1,
      showExample: false,
      sentenceExample: undefined,
      showExampleAnalysis: false,
      disableSpeaker: true,
      cptCustomNextInstructions: 0,
      
      // Might be better to store them into the database as a new field of tests or in a new table
      wordExamples: [
        ["ta dent"],
        ["ton nom"],
        ["pourquoi"],
        {example: ["feu", "peur"]},
        {example: ["une dent"]},
        {example: ["ca", "co", "cu"]},

      ]
    }
  },
  
  async created() {
    this.learner = await this.getConnectedLearner();
    this.learnerIntraGroup = getIntraGroup(this.learner.cycleId);
    await this.initItems();
    await this.playPresentation();

  },

  methods: {
    /**
     * Prepares items that will be used in the presentation
     * @return {Promise<void>}
     */
    async initItems() {
      // Get test not passed for LetterRow
      if (getIntraGroup(this.learner.cycleId) <= 1) {
        this.resultsNotPassed = await cycleService.getTestsCycle(this.learner.cycleId, true);
      }

      // Populates Sound tablet
      const allPrevLearningGroup = getAllLearningGroups('D');
      for (let learningGroup of allPrevLearningGroup) {
        this.soundTabletsDebutantItems.push(await resultsService.getComputedResults(this.learner.id, learningGroup[0], learningGroup[1], this.learner.previous_cycle));
      }

    },

    /**
     *
     * @return {Promise<void>}
     */
    async playPresentation() {
      await playTTS('Bien', 500, 1, "98%");
      this.showRow = true;
      this.showAllLetters = false;
      await playTTS("Voici le premier groupe de lettres spéciales. Je les appelle comme ça, parce que ces lettres " +
          "peuvent faire le même son que certaines lettres de ta tablette des sons.  Il faut juste apprendre à les " +
          "reconnaître. Pour continuer à t’aider", 12500, 1, "98%");
      this.showEvalRow = true;
      await playTTS('je vais encore te présenter des jeux-questionnaires ', 2500, 1, "98%");
      this.showPracticeRow = true;
      await playTTS('et des pratiques de lecture. ', 2500, 1, "98%");
      this.showTablet = true;
      await playTTS('Ta tablette des sons t’aidera à apprendre les sons que font les lettres spéciales. ', 4500, 1, "98%");
      await playTTS('Commençons par une petite pratique.  ', 3000, 1, "98%");
      await playTTS('Regarde les deux premières lettres spéciales.', 2000, 1, "98%");
      this.indexTarget++;
      this.currentTarget = this.resultsNotPassed[this.indexTarget];
      this.currentTarget.focus = true;
      await playTTS('Tu vas rencontrer souvent ces deux lettres dans les prochains mots à lire.  Clique sur le ' +
          'petit haut-parleur pour entendre le son qu’elles font', 4500, 1, "98%");
      this.disableSpeaker = false;
    },

    /**
     * Updates current target
     */
    async updateCurrentTarget() {
      /* updates new target */
      if (this.indexTarget !== this.resultsNotPassed.length - 1) {
        this.currentTarget.focus = false;
        this.indexTarget++;
        this.currentTarget = this.resultsNotPassed[this.indexTarget];
        this.currentTarget.focus = true;
      }
    },

    /** Plays Text to speech of currentTarget by default or the specified text
     * @param letter : Object , the object that represents a letter
     * @param isLetterClicked : boolean true if the method is called by a click on a letter, false if not
     * @return {Promise<void>}
     */
    async playTextToSpeech(letter, isLetterClicked = true) {
      try {
        if (isLetterClicked && !this.isFirstPartOver || this.disableSpeaker) {
          return;
        }

        this.disableSpeaker = true;
        this.cptCustomNextInstructions++;
        await playGraphemeAudioFile(letter.phoneme, 2000);

        // First Click has a special reaction
        if (this.currentTarget.phoneme === 'an') {
          await playTTS('Tu as entendu?  C’est le même son que celui-là', 3000, 1, "98%");
          this.letterToHighlight = this.currentTarget.phoneme;
          await playTTS('dans ta tablette des sons.', 2000, 1, "98%");
          await playTTS('Quand tu voudras de l’aide, je vais ajouter au-dessus  le bon son à lire', 4000, 1, "98%");

          this.currentTarget.showPhonetique = true;
          await playTTS('Comme cela.', 2000, 1, "98%");
          await this.displayExample();
          await playTTS('Voici un exemple. Si tu demande l’aide,', 2500, 1, "98%");

          this.showExampleAnalysis = true;
          await playTTS('Tu dois donc lire', 2000, 1, "97%");
          await playTTS('Ta dent', 5000, 0.50, "98%");
          await this.reset();
          await this.updateCurrentTarget();

          // Second Letter
          await playTTS('Continuons.  Pour chaque lettre spéciale, je vais te présenter le son correspondant dans' +
              ' ta tablette des sons, et l’aide que je te donnerai quand tu me le demanderas.', 8800, 1, "98%");
          await playTTS('Pour ces lettres spéciales, voici le son dans ta tablette', 3500, 1, "98%");
          this.letterToHighlight = this.currentTarget.phoneme;
          await playGraphemeAudioFile(this.currentTarget.phoneme, 1500);
          await playTTS('et l’aide au-dessus.', 2500, 1, "98%");
          this.currentTarget.showPhonetique = true;
          await playTTS('Par exemple', 1500, 1, "98%");
          this.sentenceExample = await PhoneticAnalysisService.getAnalysisAsWords(['ton nom']);
          this.showExample = true;
          await playTTS('si tu vois ce mots,', 1500, 1, "98%");
          this.showExampleAnalysis = true;
          await playTTS('c’est comme ça que tu dois le lire ', 2500, 1, "98%");
          await playTTS('Ton nom', 5000, 0.50, "98%");
          await this.reset();
          await this.updateCurrentTarget();


          // Third letter
          await playTTS('Dans ta tablette des sons, ', 2500, 1, "98%");
          this.letterToHighlight = this.currentTarget.phoneme;
          await playTTS('tu sais que cette lettre fait le son', 2500, 1, "98%");
          await playGraphemeAudioFile(this.currentTarget.phoneme, 1500);
          await playTTS('Eh bien ces deux lettres spéciales font le même son. Et voici l’aide ', 4000, 1, "98%");
          this.currentTarget.showPhonetique = true;
          await playTTS('que je vais te donner quand tu voudras, ', 2500, 1, "98%");
          await this.displayExample();
          this.showExampleAnalysis = true;
          await playTTS('comme pour le mot ', 2500, 1, "98%");
          await playTTS('pourquoi', 4000, 0.50, "98%");
          await this.reset();
          await this.updateCurrentTarget();

          // Fourth letter
          await playTTS('Continuons à découvrir les sons que font les lettres spéciales, ', 3500, 1, "98%");
          this.letterToHighlight = 'eu';
          await playTTS('Tu te souviens du son que font ces lettres. dans ta tablette. ', 3500, 1, "98%");
          await playGraphemeAudioFile(this.currentTarget.phoneme, 1500);
          await playTTS('Mais, quelque fois          ', 1500, 1, "98%");
          await playTTS('ces deux mêmes lettres font  un autre son que tu connais aussi. Regarde ta tablette et écoute', 3500, 1, "98%");
          await playGraphemeAudioFile(this.currentTarget.phoneme, 1500);
          this.letterToHighlight = this.currentTarget.phoneme;
          await this.reset();
          await this.updateCurrentTarget();

        }

        this.disableSpeaker = false;
      } catch (e) {
        this.disableSpeaker = false;
        console.log(e);
      }
    },

    async displayExample() {
      this.sentenceExample = await PhoneticAnalysisService.getAnalysisAsWords(this.wordExamples[this.indexTarget]);
      this.showExample = true;
    },

    async reset() {
      this.showExample = false;
      this.showExampleAnalysis = false;
      this.letterToHighlight = '';
    },

    /**
     * Returns the connected learner
     * @returns Learner Object on success or null on failure
     */
    async getConnectedLearner() {
      const code = this.$session.get("learnerConnectedCode");
      const learner = await getLearner(code);

      if (learner !== null && learner !== undefined) {
        return learner;
      } else {
        console.log("Could not get Session !");
      }
    },
  }


}
</script>

<style scoped>
.hidden {
  visibility: hidden;
}
</style>